.App {
  text-align: center;
  background-color: white;
  color: black;
  margin-right: 5%;
  margin-left: 5%;
  margin-top: 4%;
  padding-bottom: 2%;
  margin-bottom: 4%;
  border-radius: 10px;
}

.altrImg {
  text-align: left !important;
}

.altrImg > a > img {
  margin-left: 1%;
  margin-top: 1%;
  position: absolute;
}

body {
  /* background-color: rgba(66, 51, 230, 1); */
  background: linear-gradient(242.99deg, #6335E6 0%, #4435E6 84.52%);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.stepDiv {
  width: 29.33%;
  float: left;
  text-align: center;
  margin-right: 2%;
  margin-left: 2%;
  line-height: 105%;
}

.App-header {
  /* background-color: #282c34; */
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 10vh;
}

.App-link {
  color: #61dafb;
}

.instructions {
  color: black;
  width: 100%;
}

.instructions p {
  font-size: 18px;
}

#nextButton, #submitButton {
  background-color: rgba(66, 51, 230, 1);
}

#copyOne, #copyTwo, #copyThree {
  color: rgba(66, 51, 230, 1);
  border-color: rgba(66, 51, 230, 1);
}

.snippetDesc {
  /* background-color: rgba(66, 51, 230, 0.2); */
  background-color: white;
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
  padding: 1vh;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.snippet {
  font-size: 14px;

  text-align: left;
  font-family: monospace;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
