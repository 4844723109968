.mainForm div
{
    margin-bottom: 10px;
    margin-right: 3px;
}

.mainForm {
    text-align: center;
}

p.emailLabel {
    font-size: 120%;
}